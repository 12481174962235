import { Link } from "gatsby";
import React from "react";
import ArticleCardV2 from "./ArticleCardV2";

interface SimilarContentRowProps {
  content: any[];
  title: string;
  columnSize: string;
}

interface Sizes {
  [key: string]: string;
}

const columnConfig: Sizes = {
  two: "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2",
  three: "sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3",
};

export default function SimilarContentRow(props: SimilarContentRowProps) {
  return (
    <div className="">
      <div className="font-inter text-2xl">{props.title}</div>
      <div className={`grid gap-4 ${columnConfig[props.columnSize]} py-8 px-4`}>
        {props.content.map((element) => {
          return (
            <Link
              to={element.frontmatter.slug}
              partiallyActive={true}
              onClick={() => {
                // gtag isn't available in localhost so using this workaround
                if (window && !window.location.href.includes("localhost")) {
                  window.gtag(`event`, `click`, {
                    event_category: `recommendation`,
                    event_label: element.frontmatter.slug,
                  });
                }
              }}
            >
              <ArticleCardV2
                title={
                  element.frontmatter.title
                    ? element.frontmatter.title
                    : element.frontmatter.name
                }
                description={element.frontmatter.description}
                dynamicImage={element.frontmatter.heroImageUrl}
                tags={element.frontmatter.tags}
                key={element.frontmatter.slug}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
}
