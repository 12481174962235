import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface TwitterFollowChipProps {
  twitterUsername: string;
}

export default function TwitterFollowChip(props: TwitterFollowChipProps) {
  return (
    <OutboundLink
      target="__blank"
      href={`https://twitter.com/intent/follow?screen_name=${props.twitterUsername}`}
    >
      <div className="rounded-full px-3 py-1 bg-sky-500 flex flex-row hover:bg-sky-600">
        <div className="w-5 h-5">
          <StaticImage
            src="../images/twitter_logo_white.png"
            className=""
            alt="Twitter logo"
            placeholder="blurred"
            width={20}
            height={20}
            transformOptions={{ fit: "inside" }}
          />
        </div>
        <div className="text-white text-sm font-bold ml-2">
          {`Follow @${props.twitterUsername}`}
        </div>
      </div>
    </OutboundLink>
  );
}
