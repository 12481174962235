import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  XIcon,
  WhatsappIcon,
} from "react-share";

interface ShareRowProps {
  url: string;
  articleTitle: string;
  articleDescription: string;
}

export default function ShareRow(props: ShareRowProps) {
  const title = `Check this out - ${props.articleTitle}`;
  return (
    <div className="w-full flex flex-row items-center justify-center my-4 mx-2">
      <div className="space-x-2 sm:space-x-4 md:space-x-6 lg:space-x-6 ">
        <EmailShareButton
          subject="Check this out!"
          body={`I found this interesting article and thought you might like it`}
          separator=" - "
          url={props.url}
          onClick={() => {
            // gtag isn't available in localhost so using this workaround
            if (window && !window.location.href.includes("localhost")) {
              window.gtag(`event`, `click`, {
                event_category: `share`,
                event_label: `email`,
              });
            }
          }}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>

        <TwitterShareButton
          url={props.url}
          title={`${title} by @vinaygaba`}
          hashtags={["AndroidDev", "JetpackCompose"]}
          related={["vinaygaba"]}
          onClick={() => {
            // gtag isn't available in localhost so using this workaround
            if (window && !window.location.href.includes("localhost")) {
              window.gtag(`event`, `click`, {
                event_category: `share`,
                event_label: `twitter`,
              });
            }
          }}
        >
          <XIcon size={32} round />
        </TwitterShareButton>

        <FacebookShareButton
          url={props.url}
          onClick={() => {
            // gtag isn't available in localhost so using this workaround
            if (window && !window.location.href.includes("localhost")) {
              window.gtag(`event`, `click`, {
                event_category: `share`,
                event_label: `facebook`,
              });
            }
          }}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <LinkedinShareButton
          url={props.url}
          title={title}
          summary={props.articleDescription}
          source="JetpackCompose.app"
          onClick={() => {
            // gtag isn't available in localhost so using this workaround
            if (window && !window.location.href.includes("localhost")) {
              window.gtag(`event`, `click`, {
                event_category: `share`,
                event_label: `linkedin`,
              });
            }
          }}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <RedditShareButton
          url={props.url}
          title={title}
          onClick={() => {
            // gtag isn't available in localhost so using this workaround
            if (window && !window.location.href.includes("localhost")) {
              window.gtag(`event`, `click`, {
                event_category: `share`,
                event_label: `reddit`,
              });
            }
          }}
        >
          <RedditIcon size={32} round />
        </RedditShareButton>

        <WhatsappShareButton
          url={props.url}
          title={title}
          separator=" - "
          onClick={() => {
            // gtag isn't available in localhost so using this workaround
            if (window && !window.location.href.includes("localhost")) {
              window.gtag(`event`, `click`, {
                event_category: `share`,
                event_label: `whatsapp`,
              });
            }
          }}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
  );
}
