import "./articlePost.css";
import "./gist.css";
import Divider from "@material-ui/core/Divider";
import Layout from "../core/Layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import NewsletterRow from "../newsletter/NewsletterRow";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import AuthorCard from "./AuthorCard";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  getSrc,
} from "gatsby-plugin-image";
import TwitterFollowChip from "../../tailwindComponents/TwitterFollowChip";
import Avatar from "../../tailwindComponents/Avatar";
import SimilarContentRow from "../../tailwindComponents/SimilarContentRow";
import { shuffleArray } from "../../utils/ArrayUtils";
import { Container, Hidden } from "@material-ui/core";
import AdCTA from "../../tailwindComponents/AdCTA";
import ShareRow from "../../tailwindComponents/ShareRow";
import { MDXProvider } from "@mdx-js/react";
import BlurryNewsletterRow from "../../tailwindComponents/BlurryNewsletterRow";
import {
  auth,
  onAuthStateChangedFirebase,
  signInWithEmailLinkHandler,
} from "../../firebase/firebaseConfig";
import { isSignInWithEmailLink } from "firebase/auth";
import { format } from "date-fns";
import { isMobileDevice } from "../../utils/UserAgentUtils";
import subscribe from "../../api/subscribe";
import NewsletterMainSponsor from "../../tailwindComponents/NewsletterMainSponsor";
require("prismjs/themes/prism-tomorrow.css");

interface ArticlePostProps extends RouteComponentProps {
  pageContext: {
    data: any;
    featuredJobs: any[];
    otherArticles: any[];
    isArticle: boolean;
  };
}

export default function ArticlePost(props: ArticlePostProps) {
  const { frontmatter, body } = props.pageContext.data;
  const articleUrl = "https://www.jetpackcompose.app" + frontmatter.slug;
  const twitterShareUrl =
    'http://twitter.com/share?text=I just read "' +
    frontmatter.title +
    '" by @vinaygaba&url=https://www.jetpackcompose.app' +
    frontmatter.slug +
    "&hashtags=\nAndroidDev,JetpackCompose";
  // https://developers.google.com/search/docs/advanced/structured-data/faqpage

  const heroImageOriginalUrl = getSrc(frontmatter.heroImageUrl);
  const containsSubscriptionOnlyContent =
    frontmatter.containsSubscriptionOnlyContent;
  const heroImage: IGatsbyImageData | undefined = getImage(
    frontmatter.heroImageUrl?.childImageSharp?.gatsbyImageData
  );

  const [randomArticles, setRandomArticles] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: frontmatter.title,
    image: [`https://www.jetpackcompose.app${heroImageOriginalUrl}`],
    datePublished: frontmatter.date,
    author: [
      {
        "@type": "Person",
        name: frontmatter.authorName,
        url: frontmatter.authorProfileUrl,
      },
    ],
    isAccessibleForFree: !containsSubscriptionOnlyContent || isSubscribed,
    ...(containsSubscriptionOnlyContent && {
      hasPart: [
        {
          "@type": "WebPageElement",
          isAccessibleForFree: true,
          cssSelector: ".excerpt",
        },
        {
          "@type": "WebPageElement",
          isAccessibleForFree: false,
          cssSelector: ".subscription-only-content",
        },
      ],
    }),
  };

  useEffect(() => {
    // By pass auth wall on mobile because of how Firebase popup
    // authentication doesn't work well on mobile. Need to migrate
    // to the redirect method but it's surprisingly complex.
    if (isMobile) {
      setIsSubscribed(true);
    } else {
      const unsubscribe = onAuthStateChangedFirebase(async (user) => {
        if (user) {
          // User is signed in
          setIsSubscribed(true);
        } else if (isSignInWithEmailLink(auth, window.location.href)) {
          // User is not signed in but we have an email link in the URL
          const email = window.localStorage.getItem("emailForSignIn");
          if (email) {
            try {
              const result = await signInWithEmailLinkHandler(
                email,
                window.location.href
              );
              await subscribeUser(result.user);
              window.localStorage.removeItem("emailForSignIn");
              setIsSubscribed(true);
            } catch (error) {
              console.error("Error signing in with email link", error);
            }
          }
        } else {
          setIsSubscribed(false);
        }
      });
      return () => unsubscribe();
    }
  }, [isMobile]);

  useEffect(() => {
    setRandomArticles(
      shuffleArray(props.pageContext.otherArticles).slice(0, 2)
    );
  }, []);

  const formattedDate = frontmatter.date
    ? format(new Date(frontmatter.date), "MMMM dd, yyyy")
    : null;

  return (
    <>
      <Layout
        maxWidth="xl"
        pageTitle={frontmatter.title}
        pageDescription={frontmatter.description}
        pageSlug={frontmatter.slug}
        pageImageUrl={heroImageOriginalUrl}
        seoImageUrl={heroImageOriginalUrl}
        pageType="article"
        structuredData={structuredData}
      >
        <div className="topLevelContainer">
          <div className="sideColumn"></div>
          <Container maxWidth="md" className="mainColumn">
            <div className="articlePostContainer">
              <div className="postHeaderContainer">
                <h1 className="postHeader">{frontmatter.title}</h1>
                <div className="postDescription">{frontmatter.description}</div>
                <div className="postSubheader">
                  <Avatar
                    name={frontmatter.authorName}
                    image={frontmatter.authorImageUrl}
                  />
                  <span className="authorName">
                    {frontmatter.authorName + " "}{" "}
                  </span>
                  {"  on "}
                  {formattedDate}
                </div>
                <div className="postHeaderTwitterFollowButton">
                  <TwitterFollowChip twitterUsername="vinaygaba" />
                </div>
                <ShareRow
                  url={articleUrl}
                  articleTitle={frontmatter.title}
                  articleDescription={frontmatter.description}
                />
              </div>
              {(props.pageContext.isArticle || !isSubscribed) && (
                <GatsbyImage
                  image={heroImage as IGatsbyImageData}
                  className="heroImage"
                  alt="Hero Image"
                />
              )}

              {!props.pageContext.isArticle && isSubscribed && (
                <NewsletterMainSponsor
                  ctaUrl="https://engineering.squarespace.com/blog/2024/unfolds-modern-mobile-release-process-and-the-subtle-art-of-making-them-boring?utm_source=jetpack&utm_medium=newsletter&utm_campaign=julysponsors"
                  ctaText="Read Article"
                />
              )}

              <MDXProvider
                components={{
                  wrapper: ({ children }) => {
                    const filteredChildren = children.filter(
                      (child) => child.props && child.props["data-excerpt"]
                    );
                    if (!isSubscribed) {
                      if (containsSubscriptionOnlyContent) {
                        const unfilteredChildren = children.filter(
                          (child) =>
                            !child.props || !child.props["data-excerpt"]
                        );
                        return (
                          <>
                            <div className="excerpt">{filteredChildren}</div>
                            <div className="subscription-only-content subscription-only-hidden">
                              {unfilteredChildren}
                            </div>
                          </>
                        );
                      } else {
                        return <>{children}</>;
                      }
                    }
                    return <>{children}</>;
                  },
                }}
              >
                <MDXRenderer
                  className="blog-post-content"
                  frontmatter={frontmatter}
                  twitterShareUrl={twitterShareUrl}
                  featuredJobs={props.pageContext.featuredJobs}
                >
                  {body}
                </MDXRenderer>
              </MDXProvider>

              {containsSubscriptionOnlyContent && !isSubscribed && (
                <BlurryNewsletterRow />
              )}

              {(!containsSubscriptionOnlyContent || isSubscribed) && (
                <>
                  <ShareRow
                    url={articleUrl}
                    articleTitle={frontmatter.title}
                    articleDescription={frontmatter.description}
                  />
                  <Divider className="divider" />
                  <AuthorCard
                    authorName={frontmatter.authorName}
                    authorImageUrl={frontmatter.authorImageUrl}
                  />
                  <SimilarContentRow
                    content={randomArticles}
                    title="If you like this article, you might also like:"
                    columnSize="two"
                  />
                  {props.pageContext.isArticle ? (
                    <NewsletterRow />
                  ) : (
                    <NewsletterRow
                      tagline={
                        <>
                          Think of us as your Android barista – we know your
                          order, and yes, we add the extra shot of clarity
                        </>
                      }
                    />
                  )}
                </>
              )}
            </div>
          </Container>
          <div className="sideColumn"></div>
        </div>
      </Layout>
    </>
  );
}

const subscribeUser = async (user: any) => {
  const response = await fetch("/api/subscribe", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: user.email,
      referringSite: window.location.href,
    }),
  });

  if (response.ok) {
  } else {
    console.error("Error subscribing user to newsletter");
  }
};
