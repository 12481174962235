import React from "react";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";

interface AvatarProps {
  image: any;
  name: string;
}

export default function Avatar(props: AvatarProps) {
  const dynamicImage: IGatsbyImageData | undefined = getImage(
    props.image?.childImageSharp?.gatsbyImageData
  );
  return (
    <div className="w-10 h-10 rounded-full">
        <GatsbyImage
        image={dynamicImage as IGatsbyImageData}
        className="rounded-full"
        alt={`${props.name} Profile Image`}
        />
    </div>
  );
}
