// AuthComponent.tsx
import React, { useState } from "react";
import {
  signInWithGoogle,
  signInWithGithub,
  sendSignInLinkToEmailAddress,
  signOutFromFirebase,
} from "../firebase/firebaseConfig";
import { EmailIcon } from "react-share";
import { SocialIcon } from "react-social-icons";
import "react-social-icons/github";
import "react-social-icons/google";
import { StaticImage } from "gatsby-plugin-image";

// Component that handles authentication for content that's behind a
// subscription wall. Currently supports auth with Google, Github, and
// Email.
const AuthComponent: React.FC = () => {
  const [email, setEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);

  const signInWithGoogleAuth = async () => {
    try {
      const result = await signInWithGoogle();
      // Handle successful authentication
      subscribeUser(result.user);
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const signInWithGithubAuth = async () => {
    try {
      const result = await signInWithGithub();
      // Handle successful authentication
      subscribeUser(result.user);
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const handleSendSignInLink = async () => {
    try {
      await sendSignInLinkToEmailAddress(email);
      window.localStorage.setItem("emailForSignIn", email);
      alert("A sign-in link has been sent to your email address.");
    } catch (error) {
      console.error("Error sending sign-in link", error);
    }
  };

  const signOut = async () => {
    try {
      await signOutFromFirebase();
      window.localStorage.removeItem("emailForSignIn");
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const subscribeUser = async (user: any) => {
    const response = await fetch("/api/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user.email,
        referringSite: window.location.href,
      }),
    });

    if (response.ok) {
      // User subscribed successfully
    } else {
      console.error("Error subscribing user to newsletter");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-4">
        <StaticImage
          src="../images/mo_welcome.png"
          alt="Welcome to the VIP club"
          placeholder="blurred"
          height={250}
          width={250}
          transformOptions={{ fit: "inside" }}
        />
      </div>
      <div className="font-nunitoSans text-3xl text-center font-semibold mb-8 text-text">
        To read the rest of the post, subscribe and never miss an issue!
      </div>
      <div className="flex flex-col space-y-4">
        {showEmailInput ? (
          <>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border p-2 rounded mb-2"
            />
            <button
              onClick={handleSendSignInLink}
              className="text-text py-2 px-3 rounded-full border"
            >
              <div className="flex flex-row space-x-2">
                <EmailIcon size={24} round />
                <div className="text-center flex-grow">Send Sign-in Link</div>
              </div>
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => setShowEmailInput(true)}
              className="text-text py-2 px-3 rounded-full border"
            >
              <div className="flex flex-row space-x-2">
                <EmailIcon size={24} round />
                <div className="text-center flex-grow">Login with Email</div>
              </div>
            </button>
            <button
              onClick={signInWithGoogleAuth}
              className="text-text py-2 px-3 rounded-full border"
            >
              <div className="flex flex-row space-x-2 justify-center items-center">
                <img src="/google-logo.svg" alt="Google Icon" className="h-6" />
                <div className="text-center flex-grow">Login with Google</div>
              </div>
            </button>
            <button
              onClick={signInWithGithubAuth}
              className="text-text py-2 px-3 rounded-full border"
            >
              <div className="flex flex-row space-x-2">
                <SocialIcon
                  url="https://github.com"
                  style={{ height: 24, width: 24 }}
                />
                <div className="text-center flex-grow">Login with Github</div>
              </div>
            </button>
          </>
        )}
      </div>
      <div className="m-4 font-inter text-center">
        Already a subscriber? Use the same email id to login again.
      </div>
    </div>
  );
};

export default AuthComponent;
