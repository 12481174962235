import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

interface NewsletterMainSponsorProps {
  ctaUrl: string;
  ctaText: string;
}

export default function NewsletterMainSponsor(
  props: NewsletterMainSponsorProps
) {
  return (
    <div className="w-full mb-16">
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center w-full lg:w-2/3 bg-[#F4F2EC] rounded-xl p-8 text-lg font-inter">
          <div>
            <OutboundLink
              className="mt-12"
              target="__blank"
              href={props.ctaUrl}
            >
              <StaticImage
                src="../images/newsletter/supported-by-runway.png"
                alt="Supported by Runway"
                className="w-60"
                placeholder="blurred"
              />
            </OutboundLink>
          </div>
          <div className="mt-12 whitespace-pre-wrap text-center">
            Can a mobile team make their releases boring? <br />
            <br />
            Squarespace’s Unfold team did! Read what their non-eventful mobile
            releases are like and how they came about, dig into how eventful
            they used to be, and hear why having uneventful releases is a
            superpower.
          </div>
          <OutboundLink className="mt-12" target="__blank" href={props.ctaUrl}>
            <button className="px-4 py-2 bg-accent rounded-3xl text-white">
              {props.ctaText}
            </button>
          </OutboundLink>
        </div>
      </div>
    </div>
  );
}
