export const isMobileDevice = (): boolean => {
  if (typeof navigator !== "undefined") {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return true;
    }

    // Android detection
    if (/android/i.test(userAgent)) {
      return true;
    }

    // Other mobile user agents can be added here
    return /Mobile|Opera Mini|IEMobile|WPDesktop/.test(userAgent);
  }

  return false;
};
