import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import React from "react";
import TwitterFollowChip from "../../tailwindComponents/TwitterFollowChip";
import Avatar from "../../tailwindComponents/Avatar";

interface AuthorCardProps {
  authorName: string;
  authorImageUrl: string;
}

export default function AuthorCard(props: AuthorCardProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={1}>
          <Avatar name={props.authorName} image={props.authorImageUrl} />
        </Grid>
        <Grid item xs={12} md={11}>
          <div className={classes.authorContainer}>
            <div className={classes.authorName}>{props.authorName}</div>
            <div className={classes.authorTwitter}>
              <TwitterFollowChip twitterUsername="vinaygaba" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={11}>
          <div className={classes.authorDescription}>
            Vinay Gaba is a Google Developer Expert in Android and currently
            serves as a Tech Lead Manager at Airbnb, where he spearheads the UI
            Tooling Foundation team. His team’s mission is to enhance developer
            productivity through cutting-edge tools leveraging LLMs and
            Generative AI. With a career spanning ~14 years, Vinay has deep
            expertise in UI Infrastructure, Developer Tooling, Design Systems
            and Figma Plugins. Prior to Airbnb, he worked at Snapchat, Spotify,
            and Deloitte. Vinay holds a Master's degree in Computer Science from
            Columbia University and has been at the forefront of Android
            development since 2011
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    background: "#FFFFFF",
    borderRadius: 8,
    marginLeft: 32,
    marginRight: 32,
    marginBottom: 32,
  },
  authorContainer: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  authorName: {
    fontSize: 18,
    fontFamily: "Inter",
    fontWeight: 500,
    color: "#444444",
    backgroundColor: "lightgoldenrodyellow",
    display: "inline-block",
    alignContent: "center",
    justifyContent: "center",
    marginRight: 8,
  },
  authorTwitter: {
    display: "inline-block",
    alignContent: "center",
    justifyContent: "center",
  },
  authorDescription: {
    fontSize: 16,
    fontFamily: "Nunito Sans",
    fontWeight: 400,
    color: "#717171",
  },
}));
