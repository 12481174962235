// BlurryNewsletterRow.tsx
import React from "react";
import NewsletterRow from "../components/newsletter/NewsletterRow";
import AuthComponent from "./AuthComponent";

const BlurryNewsletterRow: React.FC = () => {
  return (
    <div className="relative -mt-96">
      {" "}
      {/* Offset the component by 250px */}
      <div className="relative h-96 w-full">
        {/* Blurry region height */}
        <div className="w-full absolute inset-0 bg-gradient-to-b from-transparent to-white" />
        {/* Blurry effect */}
      </div>
      <div className="p-6 w-full">
        <AuthComponent />
      </div>
    </div>
  );
};

export default BlurryNewsletterRow;
